const { axiosException } = require('../exception/axios')
const {
  originsResponse,
  searchResponse,
  productResponse,
  crossSellingResponse,
  showcaseResponse,
  brandShowcaseResponse
} = require('../models/catalog')
const { axios } = require('../services/axios')
const {
  BACKEND_CATALOG_ORIGINS,
  BACKEND_CATALOG_PRODUCTS_URL,
  BACKEND_CATALOG_PRODUCTS_BY_CATEGORY_URL,
  BACKEND_CATALOG_PROMOTION,
  BACKEND_CATALOG_RELEASES,
  BACKEND_CATALOG_HIGHLIGHTS,
  BACKEND_CATALOG_CROSS_SELLING,
  BACKEND_CATALOG_SHOWCASE,
  BACKEND_CATALOG_BRANDSHOWCASE
} = require('../settings/kernel')

const self = {}

self.search = async (params, headers, ENDPOINT = false) => {
  try {
    const url = ENDPOINT || BACKEND_CATALOG_PRODUCTS_URL

    const { data } = await axios.get(url, {
      headers,
      params: { ...params, include: 'gift' }
    })

    return searchResponse(data)
  } catch (error) {
    throw axiosException(error, 'GET PRODUTOS BUSCA - CATALOGO')
  }
}

self.productsByCategory = async (slug, params, ENDPOINT = false) => {
  try {
    const url = ENDPOINT || BACKEND_CATALOG_PRODUCTS_BY_CATEGORY_URL

    const { data } = await axios.get(`${url}/${slug}`, {
      params: { ...params, include: 'gift' }
    })

    return searchResponse(data)
  } catch (error) {
    if (error.response.status !== 404) {
      throw axiosException(error, 'GET PRODUTOS POR CATEGORIA - CATALOGO')
    } else {
      throw new Error(error)
    }
  }
}

self.product = async (id, params = {}, ENDPOINT = false) => {
  try {
    const url = ENDPOINT || BACKEND_CATALOG_PRODUCTS_URL

    const { data } = await axios.get(`${url}/${id}`, {
      params
    })

    return productResponse(data)
  } catch (error) {
    throw axiosException(
      error,
      `GET PRODUTO BUSCA - CATALOGO ID PRODUTO: ${id}`
    )
  }
}

self.promotion = async (tag, params, ENDPOINT = false) => {
  try {
    const url = ENDPOINT || BACKEND_CATALOG_PROMOTION

    const { data } = await axios.get(`${url}/${tag}`, {
      params: { ...params, include: 'gift' }
    })

    return searchResponse(data)
  } catch (error) {
    throw axiosException(error, 'GET PROMOÇÃO - CATALOGO')
  }
}

self.releases = async (params, ENDPOINT = false) => {
  try {
    const url = ENDPOINT || BACKEND_CATALOG_RELEASES

    const { data } = await axios.get(url, {
      params: { ...params, include: 'gift' }
    })

    return searchResponse(data)
  } catch (error) {
    throw axiosException(error, 'GET RELEASES - CATALOGO')
  }
}

self.highlights = async (params, ENDPOINT = false) => {
  try {
    const url = ENDPOINT || BACKEND_CATALOG_HIGHLIGHTS

    const { data } = await axios.get(url, {
      params: { ...params, include: 'gift' }
    })

    return searchResponse(data)
  } catch (error) {
    throw axiosException(error, 'GET HIGHLIGHTS - CATALOGO')
  }
}

self.crossSelling = async (params, ENDPOINT = false) => {
  try {
    const url = ENDPOINT || BACKEND_CATALOG_CROSS_SELLING

    const { data } = await axios.get(url, {
      params
    })
    return crossSellingResponse(data)
  } catch (error) {
    throw axiosException(error, 'GET CROSS SELLING - CATALOGO')
  }
}

self.origins = async (origin_id, ENDPOINT = false) => {
  try {
    const url = ENDPOINT || BACKEND_CATALOG_ORIGINS

    const { data } = await axios.get(`${url}/${origin_id}`)

    return originsResponse(data)
  } catch (error) {
    throw axiosException(error, 'GET ORIGINS - CATALOGO')
  }
}

self.showcase = async (params, ENDPOINT = false) => {
  try {
    const url = ENDPOINT || BACKEND_CATALOG_SHOWCASE

    const { data } = await axios.get(`${url}`, {
      params
    })

    return showcaseResponse(data)
  } catch (error) {
    throw axiosException(error, 'GET VITRINE - CATALOGO')
  }
}

self.brandShowcase = async (params, ENDPOINT = false) => {
  try {
    const url = ENDPOINT || BACKEND_CATALOG_BRANDSHOWCASE

    const { data } = await axios.get(`${url}`, {
      params
    })

    return brandShowcaseResponse(data)
  } catch (error) {
    throw axiosException(error, 'GET VITRINE DE MARCA - CATALOGO')
  }
}

self.replaceUrlParams = (params, shouldPush = false) => {
  params.delete('query')
  params.delete('is_prime')
  params.delete('payload_data')

  if (shouldPush) {
    window.history.pushState(
      {},
      '',
      decodeURIComponent(`${window.location.pathname}?` + params)
    )
  } else {
    window.history.replaceState(
      {},
      '',
      decodeURIComponent(`${window.location.pathname}?` + params)
    )
  }
}

module.exports = self
